<template>
  <div ref="wrapper" class="w-100">
    <Datepicker
      wrapper-class="datepicker"
      input-class="form-control"
      calendar-class="rounded calendar"
      format="yyyy-MM-dd"
      calendar-button
      calendar-button-icon="fal fa-calendar"
      :language="ko"
      :value="value"
      @selected="handleDate"
    />
  </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import { ko } from 'vuejs-datepicker/dist/locale';
import moment from 'moment';

export default {
  components: {
    Datepicker,
  },
  props: {
    value: String,
    alignRight: Boolean,
  },
  emits: ['change'],
  data() {
    return {
      ko: ko,
      translateX: 0,
    };
  },
  mounted() {
    if (this.alignRight) {
      const wrapper = this.$refs.wrapper;
      const CALENDAR_WIDTH = 230;
      const translate = wrapper.clientWidth - CALENDAR_WIDTH;
      const calendar = document.querySelector('.calendar');

      calendar.style.transform = `translateX(${translate}px)`;
    }
  },
  methods: {
    handleDate(date) {
      const format = moment(date).format('YYYY-MM-DD');
      this.$emit('change', format);
    },
  },
};
</script>

<style lang="scss">
.datepicker {
  width: 100%;
}
.calendar {
  width: 230px !important;
}
.vdp-datepicker__calendar-button {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translate(-50%, -50%);
}
</style>
